import { useContext } from 'react';

// modules
import { FormattedMessage } from 'react-intl';

// components
import { AudioCard } from '../../../audioDescriptor/AudioCard';

// interfaces
import { PlayerData, PlayerProps, PlayerStylePlaylist } from '../../../../../interfaces/player/player.interface';

// contexts
import { AppContext } from '../../../../../contexts/AppContext';
import { PlayerContext } from '../../../../../contexts/PlayerContext';

// mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Button, Grid, ImageList, Stack } from '@mui/material';

// actions
import { handleAudioSelect, handleTopicSelection } from '../../../playlistHandlers';

// data
import { getTopicsFromAudioList } from '../../../../../utils/topicUtils';

// styles
import '../../../../styles/truncate-text.css';
import { Topic } from '../../../../../interfaces/player/playerRequest.interface';

export const PlaylistQueueCovers = () => {
    const theme = useTheme();
    const { palette } = theme;

    const { appLang, appTopics, appModals } = useContext(AppContext);
    const playerContext = useContext(PlayerContext);

    const { lang } = appLang;
    const { topics: topicList } = appTopics;

    const { current: currentContextLanguage } = lang;

    const { topicModal } = appModals;
    const { setOpenTopicModal } = topicModal;

    const { style, data, props } = playerContext;
    const { playerState } = props as PlayerProps;
    const { audioQueue } = data as PlayerData;
    const { visual, playlist } = style;

    const { primaryColor, showPlayerTitle, showPlayerDescription, truncatePlayerTitle, truncatePlayerDescription } =
        visual;

    const {
        showPlaylistAudioDate,
        showPlaylistAudioDuration,
        showPlaylistAudioPublisher,
        showPlaylistAudioTopic,
        showPlaylistImages,
    } = playlist as PlayerStylePlaylist;

    let languageAudios = audioQueue?.filter(
        audio => audio.language.substring(0, 2) === currentContextLanguage.substring(0, 2),
    );
    const topics: Topic[] = getTopicsFromAudioList(languageAudios);
    languageAudios = Array.from(new Set(languageAudios));

    const handleTopicChange = (topicLabel: string, topicId: number) => {
        window.scrollTo(0, 0);
        handleTopicSelection(playerContext, currentContextLanguage, setOpenTopicModal, topicList, topicId, topicLabel);
    };

    return (
        <Box>
            {topics.map(topic => (
                <Stack key={topic.label} spacing="0px">
                    {/* display topic */}
                    {languageAudios?.filter(
                        a => a.visible && a.topicName === topic.label && topic.id !== playerState.topicIndex,
                    ).length > 0 && (
                        <Stack
                            direction="row"
                            sx={{
                                paddingLeft: '8px',
                                paddingBottom: '6px',
                            }}
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                id={`topic-button-${topic.label}`}
                                sx={{
                                    borderColor: palette.primary.light,
                                    borderRadius: 2,
                                    color: palette.secondary.light,
                                    fontSize: '9pt',
                                    mt: 1,
                                    pb: 0,
                                    pt: '3px',
                                    px: '7px',
                                }}
                                onClick={() => topics.length > 1 && handleTopicChange(topic.label, topic.id)}
                            >
                                {topic.id > -1 && (
                                    <FormattedMessage
                                        id={`app.speech-topics-id-${topic.id}`}
                                        defaultMessage={topic.label}
                                    />
                                )}
                                {topic.id === undefined && <>{topic.label}</>}
                            </Button>
                        </Stack>
                    )}
                    {/* if no topic is selected */}
                    {languageAudios?.filter(
                        a =>
                            a.visible &&
                            a.topicName === topic.label &&
                            props?.playerState.topicIndex === 0 &&
                            topics.length > 1,
                    ).length > 0 && (
                        <ImageList
                            key={`cover-image-list`}
                            gap={5}
                            sx={{
                                height: '194px',
                                gridAutoFlow: 'column',
                                gridTemplateColumns: 'repeat(auto-fill,minmax(180px,1fr)) !important',
                                pl: 1,
                                pr: 1,
                                scrollbarWidth: 'thin',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    height: '6px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 1px rgba(0,0,0,0.00)',
                                    ml: 1,
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: alpha(primaryColor, 0.2),
                                    borderRadius: '5px',
                                },
                            }}
                        >
                            {languageAudios?.map(
                                audio =>
                                    audio.visible &&
                                    audio.topicName === topic.label && (
                                        <Box
                                            key={`audio-id-${audio.id}`}
                                            component={'div'}
                                            width={180}
                                            sx={{
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                handleAudioSelect(audio.id, playerContext, currentContextLanguage)
                                            }
                                        >
                                            <AudioCard
                                                key={`audio-card-id-${audio.id}`}
                                                audio={audio}
                                                visualStyle={{
                                                    view: 'detail',
                                                    showTitle: showPlayerTitle,
                                                    showDescription: showPlayerDescription,
                                                    showDate: showPlaylistAudioDate,
                                                    showDuration: showPlaylistAudioDuration,
                                                    showPublisher: showPlaylistAudioPublisher,
                                                    showTopic: showPlaylistAudioTopic,
                                                    showImage: showPlaylistImages,
                                                    truncateTitle: truncatePlayerTitle,
                                                    truncateDescription: truncatePlayerDescription,
                                                }}
                                            />
                                        </Box>
                                    ),
                            )}
                        </ImageList>
                    )}
                    {/* if a topic is selected */}
                    {languageAudios?.filter(
                        audio =>
                            audio.visible &&
                            audio.topicName === topic.label &&
                            (props?.playerState.topicIndex !== 0 || topics.length === 1),
                    ).length > 0 && (
                        <Grid
                            key={`audio-cards-container`}
                            container
                            spacing={1}
                            p={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                        >
                            {languageAudios?.map(
                                audio =>
                                    audio.visible &&
                                    audio.topicId === topic.id && (
                                        <Grid
                                            item
                                            key={`audio-grid-item-id-${audio.id}`}
                                            xs={6}
                                            sm={4}
                                            md={3}
                                            lg={2}
                                            xl={1.5}
                                        >
                                            <Box
                                                key={`audio-id-${audio.id}`}
                                                component={'div'}
                                                width={180}
                                                sx={{
                                                    borderRadius: '4px',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.grey[300],
                                                    },
                                                }}
                                                onClick={() =>
                                                    handleAudioSelect(audio.id, playerContext, currentContextLanguage)
                                                }
                                            >
                                                <AudioCard
                                                    key={`audio-card-id-${audio.id}`}
                                                    audio={audio}
                                                    visualStyle={{
                                                        view: 'detail',
                                                        showTitle: showPlayerTitle,
                                                        showDescription: showPlayerDescription,
                                                        showDate: showPlaylistAudioDate,
                                                        showDuration: showPlaylistAudioDuration,
                                                        showPublisher: showPlaylistAudioPublisher,
                                                        showTopic: showPlaylistAudioTopic,
                                                        showImage: showPlaylistImages,
                                                        truncateTitle: truncatePlayerTitle,
                                                        truncateDescription: truncatePlayerDescription,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    ),
                            )}
                        </Grid>
                    )}
                </Stack>
            ))}
        </Box>
    );
};
