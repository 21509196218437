import { useContext, useEffect } from 'react';

// modules
import { useLoaderData, useSearchParams } from 'react-router-dom';

// components
import { OdiaPlayer } from '../player/OdiaPlayer';
import { OdiaPlaylist } from '../playlist/OdiaPlaylist';

// contexts
import { AppContext } from '../../contexts/AppContext';

// interfaces
import { PlayerAudio, PlayerStyle, PlayerType } from '../../interfaces/player/player.interface';
import { PlayerRequestValidation } from '../../interfaces/player/playerRequest.interface';

// default player types
import { playerTypes } from '../../types/playerTypes';

// utils
import { checkColor } from '../../utils/checkColor';
import { commandParser } from '../../utils/commandParser/commandParser';
import { CssBaseline } from '@mui/material';

const defaultPlayerDesign = 'compact';
const defaultPlayerMode = 'player';
const defaultPlayerAutoPlay = false;

export const PlayerPage = () => {
    const [searchParams] = useSearchParams();
    const validationResult = useLoaderData() as PlayerRequestValidation;

    // language context
    const { appLang, appTopics, colorMode, externalCommand } = useContext(AppContext);
    const { lang, setLang } = appLang;

    const { setCommand: setExtCommand } = externalCommand;
    const { setDarkMode, setLightMode } = colorMode;

    const { setTopics } = appTopics;
    const {
        playerType: playerTypeRequest,
        playerStyle: playerStyleRequest,
        playerData,
        language: requestLanguage,
        topicList,
    } = validationResult;

    const { visual } = playerStyleRequest;
    const { themeMode } = visual;

    const audioQueue: PlayerAudio[] = playerData?.audioQueue || [];

    let playerType: PlayerType = {
        design: defaultPlayerDesign,
        mode: defaultPlayerMode,
        autoplay: defaultPlayerAutoPlay,
    };

    let playerStyle: PlayerStyle;

    const checkedColor = checkColor(searchParams.get('color') || '');

    const playerDesignRequest = playerTypeRequest.design;
    const playerModeRequest = playerTypeRequest.mode;
    const playerAutoplayRequest = playerTypeRequest.autoplay;

    // assign player type and mode
    playerType.design =
        searchParams.get('type') || (playerDesignRequest !== '' ? playerDesignRequest : defaultPlayerDesign);
    playerType.mode = searchParams.get('mode') || (playerModeRequest !== '' ? playerModeRequest : defaultPlayerMode);
    playerType.autoplay =
        searchParams.get('autoplay') === 'true' ||
        (playerAutoplayRequest ? playerAutoplayRequest : defaultPlayerAutoPlay);

    const player = playerTypes.filter(
        p =>
            p.type.mode === playerType.mode &&
            p.type.design === playerType.design &&
            p.type.autoplay === playerType.autoplay,
    );

    playerStyle = {
        ...player[0]?.style,
        ...playerStyleRequest,
    };

    const themeQuery = searchParams.get('theme');
    const themeToApply = themeQuery || themeMode;

    // apply custom color on first render
    useEffect(() => {
        if (checkedColor.isValid) {
            playerStyle.visual.primaryColor = checkedColor.color;
        }
        if (checkedColor.format === 'hex') {
            playerStyle.visual.headerColor = checkedColor.color;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (lang.current !== requestLanguage) {
            setLang({
                ...lang,
                current: requestLanguage,
            });
        }
    }, [lang, requestLanguage, setLang]);

    useEffect(() => {
        setTopics(topicList);
    }, [topicList, setTopics]);

    useEffect(() => {
        if (themeToApply === 'dark') setDarkMode();
        if (themeToApply === 'light') setLightMode();
        // eslint-disable-next-line
    }, [themeToApply]);

    // function to handle external messages from webView
    const handleMessage = (event: MessageEvent) => {
        if (typeof event?.data === 'string' && !event.data.startsWith('ima')) {
            const message = event.data;
            commandParser(message, setExtCommand);
        }
    };

    // event listener to handle external webView actions
    useEffect(() => {
        window.addEventListener('message', handleMessage, false);

        return () => {
            window.removeEventListener('message', handleMessage, false);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <CssBaseline />
            {playerType.mode === 'player' && (
                <OdiaPlayer playerType={playerType} playerStyle={playerStyle} audioQueue={audioQueue} />
            )}
            {playerType.mode === 'playlist' && (
                <OdiaPlaylist playerType={playerType} playerStyle={playerStyle} audioQueue={audioQueue} />
            )}
        </>
    );
};
