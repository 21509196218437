import { useContext } from 'react';

// contexts
import { AppContext } from '../../../../contexts/AppContext';
import { PlayerContext } from '../../../../contexts/PlayerContext';

// modules
import { FormattedMessage } from 'react-intl';

// components
import { AudioList } from '../../audioList/AudioList';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Stack } from '@mui/material';

// interfaces
import { PlayerData, PlayerProps } from '../../../../interfaces/player/player.interface';
import { PlaylistView } from '../../../../types/playlistView';

// handlers
import { handleTopicSelection } from '../../playlistHandlers';

// utils
import { getTopicsFromAudioList } from '../../../../utils/topicUtils';
import { Topic } from '../../../../interfaces/player/playerRequest.interface';

interface Props {
    view: PlaylistView;
}

export const PlaylistDetail = ({ view }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    const { appLang, appTopics, appModals } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;
    const { topics: topicList } = appTopics;

    const { topicModal } = appModals;
    const { setOpenTopicModal } = topicModal;

    const playerContext = useContext(PlayerContext);
    const { data, props } = playerContext;
    const { playerState } = props as PlayerProps;
    const { audioQueue } = data as PlayerData;

    let languageAudios = audioQueue?.filter(
        audio => audio.language.substring(0, 2) === currentContextLanguage.substring(0, 2),
    );

    const topics: Topic[] = getTopicsFromAudioList(languageAudios);

    const handleTopicChange = (topicId: number, topicLabel: string) => {
        window.scrollTo(0, 0);
        handleTopicSelection(playerContext, currentContextLanguage, setOpenTopicModal, topicList, topicId, topicLabel);
    };

    const topicButtonVariant = view === 'detail' ? 'outlined' : 'text';
    const topicButtonDesign =
        view === 'detail'
            ? {
                  borderColor: palette.secondary.light,
                  borderRadius: 8,
                  color: palette.secondary.light,
                  mt: 1,
                  px: 1,
                  py: 0,
                  fontSize: 13,
              }
            : {
                  color: palette.secondary.light,
                  mb: -2,
                  mt: 1,
                  fontSize: 22,
              };

    const selected = playerState.topicIndex > 0 ? true : topics.length === 1 ? true : false;

    return (
        <Box>
            {topics.map(topic => (
                <Stack key={`stack-${topic}`} spacing={2}>
                    {/* display topic */}
                    {languageAudios?.filter(audio => audio.visible && audio.topicId === topic.id).length > 0 && (
                        <>
                            <Stack direction="row">
                                <Button
                                    variant={topicButtonVariant}
                                    sx={topicButtonDesign}
                                    onClick={() => handleTopicChange(topic.id, topic.label)}
                                >
                                    {topic.id > -1 && <FormattedMessage id={`app.speech-topics-id-${topic.id}`} />}
                                    {topic.id === undefined && <>{topic.label}</>}
                                </Button>
                            </Stack>
                            <AudioList topic={topic.id} view={view} selected={selected} />
                        </>
                    )}
                </Stack>
            ))}
        </Box>
    );
};
